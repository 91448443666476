import React from 'react';
import Helmet from 'react-helmet';
import Home from '../containers/Home';
import ContactUs from '../containers/ContactUs';

const slides = [
  {
    title: '- FIND US -',
    showButton: false,
  },
];

const FindUs = () => (
  <div className="content">
    <Helmet>
        <title>Find us | Botafogo Brazilian Grill Huddersfield</title>
        <meta name="description" content="Book here! Come visit our Traditional Brazilian Rodizio in Huddersfield, UK" />
    </Helmet>
    <Home classNames="is-restaurant-menu is-find-us" menu slides={slides} />
    <ContactUs />
  </div>
)

export default FindUs;
